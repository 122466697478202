import React from 'react';
import { Router } from '@reach/router';
import Layout from '../components/layout';
const SomeSubPage = (props) => {
  return <div>Hi from SubPage with id: {props.id}</div>;
};

const App = () => (
  <Layout>
    <Router>
      <SomeSubPage path="/schedule/:id" />
    </Router>
  </Layout>
);

export default App;
